import { bscTokens } from '@pancakeswap/tokens'
import { getAddress } from 'viem'
import { XCAD_BNB_LP_MAINNET } from './common'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'
import { SerializedFarmConfig } from '..'

export const farmsV3 = defineFarmV3Configs([])

const farms: SerializedFarmConfig[] = [
  {
    pid: 1,
    lpSymbol: 'XCAD-WBNB LP',
    lpAddress: XCAD_BNB_LP_MAINNET,
    stakingRewardsAddress: '0x6a16E02B7557dcdd0E834D9aC3CE357a42dD7019',
    token: bscTokens.xcad,
    quoteToken: bscTokens.wbnb,
  },
].map((p) => ({
  ...p,
  token: p.token.serialize,
  quoteToken: p.quoteToken.serialize,
  lpAddress: getAddress(p.lpAddress),
  stakingRewardsAddress: getAddress(p.stakingRewardsAddress),
}))

export default farms
