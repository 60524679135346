import { useIsMounted } from "@pancakeswap/hooks";
import { AtomBox } from "@pancakeswap/ui/components/AtomBox";
import throttle from "lodash/throttle";
import React, { useEffect, useRef, useState, useMemo } from "react";
import styled, { css } from "styled-components";
import BottomNav from "../../components/BottomNav";
import { Box, Flex, Grid } from "../../components/Box";
import Footer from "../../components/Footer";
import LangSelector from "../../components/LangSelector/LangSelector";
import MenuItems from "../../components/MenuItems/MenuItems";
import { SubMenuItems } from "../../components/SubMenuItems";
import { useMatchBreakpoints } from "../../contexts";
import Logo from "./components/Logo";
import { MENU_HEIGHT, TOP_BANNER_HEIGHT, TOP_BANNER_HEIGHT_MOBILE } from "./config";
import { MenuContext } from "./context";
import { NavProps } from "./types";
import { Container } from "../../components";
import BtnAnimatedBurger from "./components/Burger";
import NavMenuMobile from "./components/NavMenuMobile";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
`;

const StyledBurger = styled(BtnAnimatedBurger)`
  margin-left: 16px;
  ${({ theme }) => theme.mediaQueries.xl} {
    display: none;
  }
`;

const StyledNav = styled.nav`
  display: grid;
  justify-content: space-between;
  align-items: center;

  transform: translate3d(0, 0, 0);
  height: ${MENU_HEIGHT}px;

  ${({ theme }) => theme.mediaQueries.xl} {
    grid-template-columns: 1fr auto 1fr;
  }

  ${({ theme }) => theme.mediaQueries.bdXl} {
    grid-auto-flow: column;
  }
`;

const MenuList = styled(AtomBox)<{ $hide: boolean }>`
  transition: opacity 150ms ease-in-out, visibility 150ms ease-in-out;

  ${({ $hide }) =>
    $hide &&
    css`
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    `}

  ${({ theme }) => theme.mediaQueries.bdLg} {
    display: none;
  }
`;

const RightSideInner = styled(Flex)<{ $hide: boolean }>`
  justify-self: end;
  transition: opacity 150ms ease-in-out, visibility 150ms ease-in-out;

  ${({ $hide }) =>
    $hide &&
    css`
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    `}
`;

const StyledOptions = styled(Flex)`
  column-gap: 8px;
  align-items: center;
  margin-right: 8px;

  ${({ theme }) => theme.mediaQueries.bdXl} {
    display: none;
  }
`;

const FixedContainer = styled.div<{ showMenu: boolean; $showModal: boolean; height: number }>`
  position: fixed;
  top: ${({ showMenu, height }) => (showMenu ? 0 : `-${height}px`)};
  left: 0;
  transition: top 0.2s;
  height: ${({ height }) => `${height}px`};
  width: 100%;
  z-index: 20;
  backdrop-filter: blur(40px);

  ${({ $showModal, theme }) =>
    !$showModal &&
    css`
      border-bottom: 1px solid ${theme.nav.borderBottom};
    `}
`;

const TopBannerContainer = styled.div<{ height: number }>`
  height: ${({ height }) => `${height}px`};
  min-height: ${({ height }) => `${height}px`};
  max-height: ${({ height }) => `${height}px`};
  width: 100%;
`;

const BodyWrapper = styled(Box)`
  position: relative;
  display: flex;
  max-width: 100vw;
`;

const Inner = styled.div`
  flex-grow: 1;
  transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  max-width: 100%;
`;

const Menu: React.FC<React.PropsWithChildren<NavProps>> = ({
  linkComponent = "a",
  banner,
  settings,
  settingsMob,
  userMenu,
  userMenuMob,
  isDark,
  toggleTheme,
  currentLang,
  setLang,
  xcadPriceUsd,
  links,
  subLinks,
  footerLinks,
  activeItem,
  activeSubItem,
  langs,
  children,
}) => {
  const { isMobile, isDesktop } = useMatchBreakpoints();
  const isMounted = useIsMounted();
  const [showMenu, setShowMenu] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const refPrevOffset = useRef(typeof window === "undefined" ? 0 : window.pageYOffset);

  const topBannerHeight = isMobile ? TOP_BANNER_HEIGHT_MOBILE : TOP_BANNER_HEIGHT;

  const totalTopMenuHeight = isMounted && banner ? MENU_HEIGHT + topBannerHeight : MENU_HEIGHT;

  const StyledUserMenuWrapper = styled.div`
    ${({ theme }) => theme.mediaQueries.bdMd} {
      display: none;
    }
  `;

  useEffect(() => {
    if (isDesktop) setShowModal(false);
  }, [isDesktop]);

  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset;
      const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight;
      const isTopOfPage = currentOffset === 0;
      // Always show the menu when user reach the top
      if (isTopOfPage) {
        setShowMenu(true);
      }
      // Avoid triggering anything at the bottom because of layout shift
      else if (!isBottomOfPage) {
        if (currentOffset < refPrevOffset.current || currentOffset <= totalTopMenuHeight) {
          // Has scroll up
          setShowMenu(true);
        } else {
          // Has scroll down
          setShowMenu(false);
        }
      }
      refPrevOffset.current = currentOffset;
    };
    const throttledHandleScroll = throttle(handleScroll, 200);

    window.addEventListener("scroll", throttledHandleScroll);
    return () => {
      window.removeEventListener("scroll", throttledHandleScroll);
    };
  }, [totalTopMenuHeight]);

  const subLinksWithoutMobile = subLinks?.filter((subLink) => !subLink.isMobileOnly);
  const subLinksMobileOnly = subLinks?.filter((subLink) => subLink.isMobileOnly);
  const providerValue = useMemo(() => ({ linkComponent }), [linkComponent]);
  return (
    <MenuContext.Provider value={providerValue}>
      <AtomBox
        asChild
        minHeight={{
          xs: "auto",
          md: "100vh",
        }}
      >
        <Wrapper>
          <FixedContainer showMenu={showMenu} $showModal={showModal} height={totalTopMenuHeight}>
            {banner && isMounted && <TopBannerContainer height={topBannerHeight}>{banner}</TopBannerContainer>}
            <Container>
              <StyledNav>
                <Logo href="/swap" />
                <MenuList $hide={showModal} justifySelf="center">
                  <MenuItems items={links} activeItem={activeItem} activeSubItem={activeSubItem} />
                </MenuList>
                <Grid justifySelf="end" gridAutoFlow="column" alignItems="center">
                  <RightSideInner $hide={showModal}>
                    <StyledOptions>
                      <LangSelector
                        currentLang={currentLang}
                        langs={langs}
                        setLang={setLang}
                        buttonScale="xs"
                        color="textSubtle"
                        hideLanguage
                      />
                      {settings}
                    </StyledOptions>
                    <StyledUserMenuWrapper>{userMenu}</StyledUserMenuWrapper>
                  </RightSideInner>
                  <StyledBurger
                    isModalOpen={showModal}
                    onClick={() => {
                      setShowModal((prev) => !prev);
                    }}
                  />
                </Grid>
              </StyledNav>
            </Container>
          </FixedContainer>
          {subLinks ? (
            <Flex justifyContent="space-around" overflow="hidden">
              <SubMenuItems
                items={subLinksWithoutMobile}
                mt={`${totalTopMenuHeight + 1}px`}
                activeItem={activeSubItem}
              />

              {subLinksMobileOnly && subLinksMobileOnly?.length > 0 && (
                <SubMenuItems
                  items={subLinksMobileOnly}
                  mt={`${totalTopMenuHeight + 1}px`}
                  activeItem={activeSubItem}
                  isMobileOnly
                />
              )}
            </Flex>
          ) : (
            <div />
          )}
          <BodyWrapper mt={!subLinks ? `${totalTopMenuHeight + 1}px` : "0"}>
            <Inner>{children}</Inner>
          </BodyWrapper>
        </Wrapper>
      </AtomBox>
      <Footer
        items={footerLinks}
        isDark={isDark}
        toggleTheme={toggleTheme}
        langs={langs}
        setLang={setLang}
        currentLang={currentLang}
        xcadPriceUsd={xcadPriceUsd}
      />
      <NavMenuMobile
        show={showModal}
        onHide={() => setShowModal(false)}
        settings={settingsMob}
        userMenu={userMenuMob}
        links={links}
        activeLink={activeItem}
      />
    </MenuContext.Provider>
  );
};

export default Menu;
